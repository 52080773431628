import apolloClient from '@/apolloClient'
import { TRANSLATE_TEXT_MUTATION } from "@/store/graphql";

export default {

  translateText(sourceLanguage, targetLanguage, text) {
    return new Promise((resolve, reject) => {

      let params = {
        query: TRANSLATE_TEXT_MUTATION,
        variables: {
          sourceLanguage: sourceLanguage,
          targetLanguage: targetLanguage,
          text: text,
        }
      }

      apolloClient.query(params)
        .then(result => {
          resolve(result.data.translateText.text)
        }).catch(error => {
          reject()
        })
    })


  }

}


