<template>
  <div class="main-view">

    <v-btn color="primary" class="float-right" :to="{name: 'news-admin-add'}">Anlegen</v-btn>
    <h1 class="headline">News bearbeiten</h1>


    <v-simple-table class="entryRestrictions" style="clear: both">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Title</th>
            <th class="text-left">NewsType</th>
            <th class="text-left">Wichtig bis</th>
            <th class="text-left">Land</th>
            <th class="text-left">Erstellt am</th>
            <th class="text-left">Erstellt von</th>
            <th class="text-left">Bearbeitet am</th>
            <th class="text-left">Bearbeitet von</th>
            <th class="text-left">Wichtige Änderung</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          <template v-for="newsEntry in news">
            <tr  :key="newsEntry.id">
              <td>{{ newsEntry.title }}</td>
              <td>{{ newsEntry.newsType }}</td>
              <td>{{ newsEntry.importantUntil }}</td>
              <td><span v-if="newsEntry.country">{{ newsEntry.country.nameShort }}</span></td>
              <td>{{ newsEntry.createdAt|i18n_datetimeToGerman }}</td>
              <td><span v-if="newsEntry.createdBy">{{ newsEntry.createdBy.firstName }} {{ newsEntry.createdBy.lastName }}</span></td>
              <td>{{ newsEntry.modifiedAt|i18n_datetimeToGerman }}</td>
              <td><span v-if="newsEntry.modifiedBy">{{ newsEntry.modifiedBy.firstName }} {{ newsEntry.modifiedBy.lastName }}</span></td>
              <td>{{ newsEntry.significantUpdateAt|i18n_datetimeToGerman }}</td>
              <td><v-btn color="primary" :to="{name: 'news-admin-edit', params: {newsId: newsEntry.id}}">Bearbeiten</v-btn></td>
              <td><v-btn color="primary" @click="deleteNews(newsEntry)" :loading="deleteNewsId[newsEntry.id]">Löschen</v-btn></td>
            </tr>
          </template>

        </tbody>
      </template>
    </v-simple-table>


  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"

export default {
  name: 'NewsAdminList',
  data() {
    return {
      deleteNewsId: {}
    }
  },
  mounted: function() {
    this.doNewsFetchAll()
  },
  computed: {
    ...mapState({
      news: state => state.newsStore.entries,
      newsIsLoading: state => state.newsStore.isLoading,
    }),
  },
  methods: {
    ...mapActions({
      newsFetchAll: 'newsStore/fetchAll',
      newsDelete: 'newsStore/delete',
    }),
    doNewsFetchAll() {
      this.newsFetchAll({force: true})
    },
    deleteNews(news) {
      this.$confirm('Möchten Sie ' + news.title + ' wirklich aus löschen?').then((res) => {
        if (res) {
          this.$set(this.deleteNewsId, news.id, true)
          this.newsDelete({
            id: news.id
          }).then(() => {
            this.$set(this.deleteNewsId, news.id, false)
            this.doNewsFetchAll()
          })
        }
      })
    }
  },
}
</script>

<style scoped>
  .main-view {
      padding: 2rem;
      max-width: 2000px;
      margin: auto;
  }
</style>
